import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import qs from "query-string"

import { detectMob, useInput, toastMsg } from "@src/Helper"
import { resetPassword } from "@src/http/UserRepository"
import SEO from "@components/seo";

import Button from "@src/components/Button"

import "./_authentication.scss"

function ResetPassword({ location }) {
  const password = useInput("")
  const passwordConfirmation = useInput("")
  const id = useInput("forgot-password")

  const [token, setToken] = useState("")
  const [isProceed, setIsProceed] = useState(false)

  useEffect(() => {
    const queryString = qs.parse(location.search)
    setToken(queryString.token)
    if (detectMob()) {
      id.setValue('m-forgot-password');
    }
  }, [])

  function handleFormSubmit() {
    setIsProceed(true)

    if (password.value !== passwordConfirmation.value) {
      toastMsg(
        "Error!",
        "Password confirmation doesn't match! Please check your input and try again.",
        "toast-mafia-danger"
      )
      setIsProceed(false)
      return
    }

    resetPassword({
      token: token,
      password: password.value,
    })
      .then(res => {
        toastMsg("Success!", res.data.message, "toast-mafia-success")
        setIsProceed(false)
        setTimeout(() => {
          navigate("/signin")
        }, 2500)
      })
      .catch(err => {
        toastMsg("Error!", err.response.data.message, "toast-mafia-danger")
        setIsProceed(false)
      })
  }

  return (
    <div id={id.value}>
      <SEO title="Reset Password" />
      <section className="section-200">
        <div className="container">
          <div
            className="card card-dusty-grey text-center mx-auto"
            style={{ maxWidth: 700 }}
          >
            <div className="heading text-monday-blue mb-4">Reset Password</div>

            <form
              onSubmit={e => {
                e.preventDefault()
                handleFormSubmit()
              }}
            >
              <div className="form-group text-left">
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  placeholder="Your new password"
                  onChange={e => {
                    password.setValue(e.target.value)
                  }}
                  required
                />
                <label htmlFor="password">
                  <i className="mdi mdi-lock" /> New Password
                </label>
              </div>

              <div className="form-group text-left">
                <input
                  type="password"
                  className="form-control"
                  id="password_confirmation"
                  placeholder="Confirm your new password"
                  onChange={e => {
                    passwordConfirmation.setValue(e.target.value)
                  }}
                  required
                />
                <label htmlFor="password_confirmation">
                  <i className="mdi mdi-lock" /> Confirm Password
                </label>
              </div>

              <Button
                type="submit"
                className="button btn-block button-mafia-primary d-flex"
                isDisabled={
                  password.value === "" ||
                  passwordConfirmation.value === "" ||
                  isProceed
                }
                isLoading={isProceed}
                loadingText="Submitting..."
                normalText="Reset Password"
              />
            </form>
          </div>
        </div>
      </section>
    </div>
  )
}

export default ResetPassword
